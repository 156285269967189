import React from "react";
import styled from "styled-components";
import Burger from "./Burger";

const Nav = styled.nav`
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .logo {
    padding: 15px 0;
  }
`;

const Navbar = (props) => {
    const updatedFilterHandler = (tagChanged) => {
        props.onUpdatedNavbarFilter(tagChanged);
      };
  

  return (
    <Nav>
      <Burger name={props.name} onUpdatedFilter={updatedFilterHandler} />
    </Nav>
  );
};

export default Navbar;
