// All options are in simple-react-lightbox website
export const options = {
    buttons: {
      backgroundColor: "#7aa095ff",
      iconColor: "#fff",
      iconPadding: "10px",
      showFullscreenButton: true,
      showDownloadButton: true
    },
    caption: {
      showCaption: false,
      captionColor: "#e8eddf",
      captionFontSize: "18px"
    },
    settings: {
      overlayColor: "#cccccccc",
      boxShadow: "5px 5px 15px black",
      downloadedFileName: "IJ" 
    },
    thumbnails: {
      thumbnailsAlignment: "center"
    }
  };
  