import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "bootstrap/dist/css/bootstrap.min.css";
import SimpleReactLightbox from "simple-react-lightbox";

import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <Auth0Provider
      domain="dev-5omq1br3.us.auth0.com"
      clientId="O5H1Zp60Shh2x80AJTeT1p4J7rukBSN3"
      redirectUri={window.location.origin}
    >
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </Auth0Provider>
  </StrictMode>,
  rootElement
);
