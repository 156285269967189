import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

const Div = styled.div`
  text-align: center;
  border-radius: 3px;
  border: transparent;
  padding: 30vh 100px;
  position: relative;
`;

const Button = styled.button`
  background: #7aa095ff;
  border-radius: 3px;
  border: transparent;
  color: #fff;
  margin: 1% auto;
  padding: 2vh 5vw;
  text-align: left;
  font-size: 1.2em;
  position: relative;
`;

const H1 = styled.h1`
  color: #7aa095ff;
`;

const H6 = styled.h6`
  color: #7aa095ff;
`;

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Div>
      <H1>Gràcies!</H1>
      <p> </p>
      <H6>
        Vam passar un dia increïble i tenim ganes de compartir les fotos
        amb vosaltres.
      </H6>
      <H6>Esperem que us agradin!</H6>
      <Button onClick={() => loginWithRedirect()}>Veure les fotos</Button>
    </Div>
  );
};
