import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import "./RightNav.css"

const Div = styled.div`
  list-style: none;
  display: flex;
  li {
    padding: 18px 10px;
  }

  flex-flow: column nowrap;
  background-color: #7aa095ff;
  position: fixed;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  top: 0;
  right: 0;
  height: 100vh;
  width: 300px;
  padding-top: 3.5rem;
  transition: transform 0.3s ease-in-out;
  li {
    color: #fff;
  }
  overflow-y: scroll;
`;
const Button = styled.button`
  background: transparent;
  border-radius: 3px;
  border: transparent;
  color: #fff;
  margin: 0 2em;
  padding: 18px 10px;
  text-align: left;
`;

const RightNav = (props) => {
  const { logout } = useAuth0();
  const [tag, setTag] = useState("all");

  useEffect(() => {
    props.onChangeFilter(tag);
  }, [tag]);

  if (props.name === 'IJ'){
    return (
      <Div open={props.open}>
        <TagButton
          name="Totes"
          tagActive={tag === "IJ" ? true : false}
          handleSetTag={setTag}
          tagName="IJ"
        />
        <TagButton
          name="Església"
          tagActive={tag === "església" ? true : false}
          handleSetTag={setTag}
          tagName="església"
        />
        <TagButton
          name="Aperitiu"
          tagActive={tag === "aperitiu" ? true : false}
          handleSetTag={setTag}
          tagName="aperitiu"
        />
        <TagButton
          name="Sopar"
          tagActive={tag === "sopar" ? true : false}
          handleSetTag={setTag}
          tagName="sopar"
        />
        <TagButton
          name="Festa"
          tagActive={tag === "festa" ? true : false}
          handleSetTag={setTag}
          tagName="festa"
        />
        <p/>
        <p/>
        <p/>
        <p/>
        <p/>
        <p/>
        <Button className="logout" onClick={() => logout({ returnTo: window.location.origin })}>
          Tanca Sessió
        </Button>
      </Div>
    );
  }

  else {
    return (
      <Div open={props.open}>
        <TagButton
          name="Totes"
          tagActive={tag === "PRO" ? true : false}
          handleSetTag={setTag}
          tagName="PRO"
        />
        <TagButton
          name="Casa Josep"
          tagActive={tag === "josep" ? true : false}
          handleSetTag={setTag}
          tagName="josep"
        />
        <TagButton
          name="Casa Inés"
          tagActive={tag === "ines" ? true : false}
          handleSetTag={setTag}
          tagName="ines"
        />
        <TagButton
          name="Església"
          tagActive={tag === "església" ? true : false}
          handleSetTag={setTag}
          tagName="església"
        />
        <TagButton
          name="Reportatge IJ"
          tagActive={tag === "repo" ? true : false}
          handleSetTag={setTag}
          tagName="repo"
        />
        <TagButton
          name="Aperitiu"
          tagActive={tag === "aperitiu" ? true : false}
          handleSetTag={setTag}
          tagName="aperitiu"
        />
        <TagButton
          name="Sopar"
          tagActive={tag === "sopar" ? true : false}
          handleSetTag={setTag}
          tagName="sopar"
        />
        <TagButton
          name="Festa"
          tagActive={tag === "festa" ? true : false}
          handleSetTag={setTag}
          tagName="festa"
        />
        <p/>
        <p/>
        <p/>
        <p/>
        <p/>
        <p/>
        <Button className="logout" onClick={() => logout({ returnTo: window.location.origin })}>
          Tanca Sessió
        </Button>
      </Div>
    );
  }

  /* return (
    <Div open={props.open}>
      <TagButton
        name="Totes"
        tagActive={tag === "all" ? true : false}
        handleSetTag={setTag}
        tagName="all"
      />
      <TagButton
        name="Església"
        tagActive={tag === "església" ? true : false}
        handleSetTag={setTag}
        tagName="església"
      />
      <TagButton
        name="Aperitiu"
        tagActive={tag === "aperitiu" ? true : false}
        handleSetTag={setTag}
        tagName="aperitiu"
      />
      <TagButton
        name="Sopar"
        tagActive={tag === "sopar" ? true : false}
        handleSetTag={setTag}
        tagName="sopar"
      />
      <TagButton
        name="Festa"
        tagActive={tag === "festa" ? true : false}
        handleSetTag={setTag}
        tagName="festa"
      />
      <p/>
      <p/>
      <p/>
      <p/>
      <p/>
      <p/>
      <Button className="logout" onClick={() => logout({ returnTo: window.location.origin })}>
        Tanca Sessió
      </Button>
    </Div>
  ); */
};

const TagButton = ({ name, handleSetTag, tagActive, tagName }) => {
  return (
    <Button
      className={`tag ${tagActive ? "active" : null}`}
      onClick={() => handleSetTag(tagName)}
    >
      {name}
    </Button>
  );
};

export default RightNav;
