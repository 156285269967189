import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./styles.css";
import { LoginButton } from "./components/Login";
import Wrapper from "./components/Wrapper";
import Navbar from "./components/Nav/NavBar";
import { imagesCollection } from "./imagesCollection";

import img426 from "./images/0426 Ines & Josep 040921.jpg";

function App() {
  const titleRef = useRef();

  const { user, isAuthenticated } = useAuth0();
  console.log(isAuthenticated);

  
  const [tag, setTag] = useState('IJ');
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    tag === "all"
      ? setFilteredImages(imagesCollection.filter((image) => image.tag.includes(user.name)))
      : setFilteredImages(
          imagesCollection.filter((image) => image.tag.includes(tag))
        );
  }, [tag]);

  const updatedNavbarFilterHandler = (tagChanged) => {
    console.log("from App");
    console.log(tagChanged);
    setTag(tagChanged);
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (isAuthenticated) {
    return (
      <>
        <Navbar name={user.name}
          onUpdatedNavbarFilter={updatedNavbarFilterHandler}
        />
        <img src={img426} alt=" " className="full" />
        <div className="container text-center">
          <div className="row my-5">
            <h1 ref={titleRef}>Gràcies!</h1>
            <p> </p>
            <h5>
              Vam passar un dia increïble i tenim ganes de compartir les
              fotos amb vosaltres. Esperem que us agradin!
            </h5>
            <p>
              Amb el menú de la dreta podeu filtrar les imatges per les
              diferents etapes de la celebració: a l'església, a l'aperitiu, al
              sopar i a la festa.
            </p>
          </div>
          <Wrapper user={user.name} images={filteredImages} />
        </div>
      </>
    );
  }
  return <LoginButton />;
}

export default App;
