import React from 'react';
import { SRLWrapper } from "simple-react-lightbox";
import { options } from "../options";
import './Wrapper.css'



function Wrapper(props) {

  return (
    <div>
      
      <SRLWrapper options={options}>
        <div className="row justify-content-around p-2">
          {props.images.map((image) => (
            <div className="col-md-4" key={image.id}>
              <img
                src={image.image}
                className="img-thumbnail img-fluid shadow-sm m-2 imatge"
                alt=" "
              />
            </div>
          ))}
        </div>
      </SRLWrapper>
    </div>
  );
}


export default Wrapper;
